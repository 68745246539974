
import Header from '@/components/sections/Header.vue'
export default {
  name: 'Privacy Policy',
  layout: 'delete',
  data () {
    return {
    }
  },
  components: {
    Header
  },
  methods: {
  }
}
